import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [catImages, setCatImages] = useState([]);
  const [imageState, setImageState] = useState('/images/letter_1.png');
  const [isShaken, setIsShaken] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const fetchCatImages = async () => {
      const fetchedImages = [];
      try {
        for (let i = 0; i < 10; i++) {
          const response = await axios.get('https://api.thecatapi.com/v1/images/search?limit=10');
          fetchedImages.push(...response.data.map(cat => cat.url));
        }
        setCatImages(fetchedImages);
      } catch (error) {
        console.error("Error fetching cat images", error);
      }
    };

    fetchCatImages();
  }, []);

  const handleClick = () => {
    if (isShaken) return;

    setIsShaken(true);

    // Add shake animation
    imageRef.current.classList.add('shake-animation');

    setTimeout(() => {
      setImageState('/images/letter_3.png');
      imageRef.current.classList.remove('shake-animation');
      setTimeout(() => {
        const card = document.getElementById('card');
        card.classList.add('slide-up');
      }, 500); // Delay to let the image change before animation
    }, 2000); // Shake for 2 seconds
  };

  const getRandomCatImage = () => {
    if (catImages.length > 0) {
      return catImages[Math.floor(Math.random() * catImages.length)];
    }
    return '';
  };

  return (
    <div className="app">
      <div className="grid-container">
        {[...Array(91)].map((_, index) => (
          <img key={index} src={getRandomCatImage()} alt="cute-cat" className="grid-item" />
        ))}
      </div>
      
    </div>
  );
}

export default App;

// <div className="shake-container" onClick={handleClick}>
//         <div className="image-stack">
//           <img ref={imageRef} src={imageState} alt="shake-me" id="shake-image" />
//           <img src="/images/test4.png" alt="card" id="card" />
//         </div>
//       </div>